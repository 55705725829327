<template>
  <div>
    <b-modal
      ref="my-modal"
      centered
      cancel-title="No"
      cancel-variant="outline-secondary"
      title-tag="div"
    >
      <validation-observer ref="phonesimple">
        <b-form>
          <b-row>
            <b-col
              md="4"
              xl="4"
            >
              <validation-provider
                #default="{ errors }"
                name="documentType"
                rules="required"
              >
                <b-form-group
                  label-for="documentType"
                  :state="errors.length > 0 ? false : null"
                >
                  <label> نوع الوثيقة</label>
                  <v-select
                    v-model="newDocument.document_type"
                    label="name"
                    :options="optionDocuments"
                    :reduce="(val) => val.id"
                  />
                </b-form-group>
                <b-form-invalid-feedback :state="errors.length > 0 ? false : null">
                  {{ errors[0] }}
                </b-form-invalid-feedback>
              </validation-provider>
            </b-col>
            <b-col
              md="4"
              xl="4"
            >
              <validation-provider
                #default="{ errors }"
                name="docnumber"
                rules=""
              >
                <b-form-group
                  label-for="docnumber"
                  :state="errors.length > 0 ? false : null"
                >
                  <label> رقم الوثيقة</label>
                  <b-form-input v-model="newDocument.number" />
                </b-form-group>
                <b-form-invalid-feedback :state="errors.length > 0 ? false : null">
                  {{ errors[0] }}
                </b-form-invalid-feedback>
              </validation-provider>
            </b-col>
            <b-col
              md="4"
              xl="4"
            >
              <validation-provider
                #default="{ errors }"
                name="releasedate"
                rules="required"
              >
                <b-form-group
                  label-for="releasedate"
                  :state="errors.length > 0 ? false : null"
                >
                  <label> تاريخ الأصدار</label>
                  <flat-pickr
                    v-model="newDocument.release_date"
                    class="form-control"
                    :config="{ maxDate: maxDate }"
                  />
                </b-form-group>
                <b-form-invalid-feedback :state="errors.length > 0 ? false : null">
                  {{ errors[0] }}
                </b-form-invalid-feedback>
              </validation-provider>
            </b-col>
            <b-col
              md="4"
              xl="4"
            >
              <b-form-group label-for="date">
                <label> تاريخ الأنتهاء</label>
                <flat-pickr
                  v-model="newDocument.expiration_date"
                  class="form-control"
                />
              </b-form-group>
            </b-col>
            <b-col
              md="4"
              xl="4"
            >
              <b-form-group label-for="delimiter">
                <label>الملاحظات</label>
                <b-form-textarea
                  id="textarea-default"
                  v-model="newDocument.note"
                  rows="1"
                />
              </b-form-group>
            </b-col>

          </b-row>
        </b-form>
      </validation-observer>
      <template #modal-footer>

        <b-button

          v-if="newDocument.id"
          variant="edit"
          @click="updateBenDoc(newDocument.id)"
        >
          <span class="align-middle">تعدبل الوثيقة</span>
        </b-button>

        <b-button
          v-else
          variant="outline-primary"
          @click="addnewDocument"
        >
          <span class="align-middle"> إضافة  </span>
        </b-button>
      </template>
    </b-modal>
    <validation-observer ref="simpleRules">
      <b-form>
        <br><br>
        <b-card-code title="معلومات الوثيقة">
          <b-table
            responsive="sm"
            :items="personDocument"
            :fields="fields"
            show-empty
            empty-text="لا يوجد وثائق   "
          >

            <template #cell(actions)="data">
              <b-dropdown
                variant="link"
                no-caret
              >
                <template #button-content>
                  <feather-icon
                    icon="MoreVerticalIcon"
                    size="16"
                    class="align-middle text-body"
                  />
                </template>
                <b-dropdown-item-button @click="showDocument(data.item)">
                  <feather-icon icon="EditIcon" />
                  <span class="align-middle ml-50">تعديل</span>
                </b-dropdown-item-button>

              </b-dropdown>
            </template>
          </b-table>
          <b-row>
            <b-col
              class="border-Primary"
              md="2"
              xl="1"
            >
              <b-button
                variant="purple"
                @click="adddocument()"
              >
                <span class="align-middle"> إضافة وثيقة اخر</span>
              </b-button>
            </b-col>
          </b-row>

          <br><br>
        </b-card-code>
      </b-form>
    </validation-observer>
  </div>
</template>

<script>
import BCardCode from '@core/components/b-card-code/BCardCode.vue'
import {
  BRow, BCol, BFormGroup, BInputGroupPrepend, BInputGroup,
  BFormDatepicker,
  BFormInput,
  BFormTextarea,
  BFormFile,
  BForm,
  BButton,
  BImg,
  BFormInvalidFeedback,
  BTable,
  BDropdown,
  BDropdownItem,
  BDropdownItemButton,
  BModal,
} from 'bootstrap-vue'
import flatPickr from 'vue-flatpickr-component'
// import '@core/scss/vue/libs/vue-flatpicker.scss';
import 'flatpickr/dist/flatpickr.css'
import vSelect from 'vue-select'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import { ValidationProvider, ValidationObserver } from 'vee-validate'

export default {
  components: {
    BCardCode,
    flatPickr,
    BForm,
    BTable,
    BDropdown,
    BDropdownItem,
    BDropdownItemButton,
    BModal,
    vSelect,
    ValidationProvider,
    ValidationObserver,
    BFormInvalidFeedback,
    ToastificationContent,
    BImg,
    BButton,
    BFormInput,
    BFormFile,
    BFormGroup,
    BInputGroupPrepend,
    BInputGroup,
    BFormDatepicker,

    BFormTextarea,

    BRow,

    BCol,
  },
  props: {
    personDocument: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    const now = new Date()
    const today = new Date(now.getFullYear(), now.getMonth(), now.getDate())
    const maxDate = new Date(today)
    maxDate.setMonth(maxDate.getMonth())

    return {
      optionDocuments: [],
      maxDate,
      newDocument: {},
      fields: [
        { key: 'document_type.name', label: ' نوع الوثيقة' },
        { key: 'number', label: ' رقم الوثيقة ' },
        { key: 'release_date', label: ' تاريخ الأصدار ' },
        { key: 'expiration_date', label: 'تاريخ الأنتهاء' },
        { key: 'notes', label: 'الملاحظة' },
        { key: 'actions', label: 'الخيارات' },

      ],
    }
  },

  created() {
    this.getAlldrowpDown()
  },

  methods: {
    updateBenDoc(id) {
      let data = null

      //console.log('el.expiration_date')
      data = {
        number: this.newDocument.number,
        note: this.newDocument.note,
        release_date: this.newDocument.release_date,
        expiration_date: this.newDocument.expiration_date,
        document_type: this.newDocument.document_type,
      }

      // console.log(data);
      const url = `/api/v1/pending_actions/person_documents/${id}`
      this.$http.put(url, data).then(res => {
        // console.log(res);
        this.$swal({
          title: 'تم طلب التعديل بانتظار موافقة مدير المعلومات',
          icon: 'success',
          customClass: {
            confirmButton: 'btn btn-primary',
          },
          buttonsStyling: false,
        })
      })
    },
    addnewDocument() {
      return new Promise((resolve, reject) => {
        this.$refs.phonesimple.validate().then(success => {
          if (success) {
            // this.personServices.push(this.newService)
            this.addPersonDoc()
            this.$refs['my-modal'].hide()
          }
        })
      })
    },
    addPersonDoc() {
      let data = null
      // console.log(this.personDocument);

      data = {
        person_id: this.$route.params.id,
        document_type: this.newDocument.document_type,
        notes: this.newDocument.note,
        number: this.newDocument.number,
        release_date: this.newDocument.release_date,
        expiration_date: this.newDocument.expiration_date,
      }

      // let data = {
      //   proposal_services: this.services,
      // };
      // console.log(data);

      this.$http
        .post('/api/v1/pending_actions/person_documents', data)
        .then(response => {
          this.newDocument.document_type = ''
          this.newDocument.note = ''
          this.newDocument.number = ''
          this.newDocument.release_date = ''
          this.newDocument.expiration_date = ''
          // console.log(response);
          this.$swal({
            title: '',
            text: '  تم إضافة الوثيقة   بنجاح بانتظار موافقة مدير المعلومات   ',
            icon: 'success',
            customClass: {
              confirmButton: 'btn btn-primary',
            },
            buttonsStyling: false,
          })

          requestAnimationFrame(() => {
            this.$refs.simpleRules.reset()
          })
        })
        .catch(error => {
          // console.log(error.response.data.message);
          this.$swal({
            title: `${error.response.data.message}`,
            icon: 'error',
            customClass: {
              confirmButton: 'btn btn-primary',
            },
            buttonsStyling: false,
          })
        })
    },
    getAlldrowpDown() {
      this.$http.get('/api/v1/beneficiaries_page/get_dropdown_data').then(res => {
        const { person_document_types } = res.data.data
        person_document_types.forEach(el => {
          this.optionDocuments.push({ name: el.name, id: el.id })
        })
      })
    },
    adddocument() {
      this.$refs['my-modal'].show()
      this.newDocument.document_type = ''
      this.newDocument.note = ''
      this.newDocument.number = ''
      this.newDocument.release_date = ''
      this.newDocument.expiration_date = ''
      this.newDocument.id = ''
      // this.personDocument.push({
      //   makingDate: "",
      //   expireDate: "",
      //   docNumber: "",
      //   documentType: "",
      //   docNote: "",
      // });
      // this.documents.id++;
    },
    showDocument(item) {
      this.$refs['my-modal'].show()
      this.newDocument =JSON.parse(JSON.stringify(item));
    },
    deleteDocument(index) {
      this.personDocument.splice(index, 1)
    },
  },
}
</script>

<style scoped>
label {
  color: #7e7a9f;
  font-weight: bold;
  font-family: "Cairo", sans-serif;
}
.btn-purple {
  color: white;
  background-color: #0971b8 !important;
}
.align-left {
  left: 50px;
}
.btn-edit {
  color: white;
  width: 200px;
  background-color: #28c76f !important;
}
.but {
  top: 40px;
}
</style>
